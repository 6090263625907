import { jwtDecode } from "jwt-decode";

export type LoggedInUser = {
  brandOfficeId?: number;
  email: string;
  sub: string;
  roles: string[];
};

export const parseToken: (token?: string) => LoggedInUser | undefined = (token?: string) => {
  if (!token) {
    return undefined;
  } else {
    const payload: any = jwtDecode(token);
    return {
      brandOfficeId: payload.brandOfficeId,
      email: payload.email,
      sub: payload.sub,
      roles: payload.roles.map((r: any) => r.role.name),
    };
  }
}