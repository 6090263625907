import {UserDTO} from "@dineiro/dineiro-sdk-mobile";

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["bytes", "kb", "mb", "gb", "tb", "pb", "eb", "zb", "yb"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export function generateRandomCode() {
  let code = "";
  for (let i = 0; i < 8; i++) {
    const randomDigit = Math.floor(Math.random() * 10);
    code += randomDigit;
  }
  return code;
}

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function getUniqueStartCharacters(users) {
  const initials = users
    .reduce((initials, user) => {
      const firstLetter = user.firstName.charAt(0).toUpperCase();
      if (!initials.includes(firstLetter)) {
        return [...initials, firstLetter];
      }
      return initials;
    }, [])
    .sort();

  return initials;
}

export function sortFullNames(data: UserDTO[], orderBy = 'name', isAscending = true) {
  return [...data].sort((a, b) => {
    switch (orderBy) {
      case 'name':
        return isAscending ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName);
      case 'accounts':
        return isAscending ? a.activeAccountsCount - b.activeAccountsCount : b.activeAccountsCount - a.activeAccountsCount;
      default:
        return isAscending ? a.firstName.localeCompare(b.firstName) : b.firstName.localeCompare(a.firstName);
    }
  });
}

export function sortAlphabet(data, isAscending = true) {
  const sorted = [...data].sort((a, b) => {
    if (isAscending) {
      return a.localeCompare(b);
    } else {
      return b.localeCompare(a);
    }
  });
  return sorted;
}

export function isExpired(createdAt) {
  const twentyFourHoursAgo = new Date();
  twentyFourHoursAgo.setHours(twentyFourHoursAgo.getHours() - 24);

  const createdAtDate = new Date(createdAt);

  return createdAtDate < twentyFourHoursAgo;
}

export function calculatePercentage(value1, value2, decimalPlaces) {
  const percentage = value2 && value2 !== 0 ? (value1 / value2) * 100 : 0;
  const roundedPercentage = percentage.toFixed(decimalPlaces);
  const roundedPercentageInt = parseInt(roundedPercentage, 10);
  const result =
    roundedPercentageInt === parseFloat(roundedPercentage)
      ? roundedPercentageInt
      : roundedPercentage;

  return `${result}%`;
}

export function isFileInFolder(folderName, filePath) {
  const regex = new RegExp(`^${folderName}/`);
  if (regex.test(filePath)) {
    return true;
  } else {
    return false;
  }
}

export function formatDate(date: Date): string {
  if (date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  }
  return "";
}

export function handleNavigate(tab: string): string {
  const currentUrl = window.location.pathname;
  const hasIdParam = currentUrl.includes("/");

  let cleanUrl;

  if (hasIdParam) {
    const idParamIndex = currentUrl.lastIndexOf("/");
    cleanUrl = currentUrl.substring(0, idParamIndex);
  } else {
    cleanUrl = currentUrl;
  }

  return `${cleanUrl}/${tab}`;
}

export function getLocalStorageElement(key: string): string {
  const storedToken = localStorage.getItem(key);
  if (storedToken) {
    return storedToken;
  }
  return "";
}

export function reformatPhoneNumber(phone) {
  if (phone.startsWith("+")) {
    return phone;
  } else {
    return "+61" + phone.substring(1);
  }
}

export const handleDownload = (downloadLink) => {
  const link = document.createElement("a");

  link.href = downloadLink;

  document.body.appendChild(link);

  link.click();

  link.parentNode.removeChild(link);
};

export function checkObjectHasData(obj) {
  // Loop through all properties of the object (excluding methods)

  const checkData = (value) => {
    if (
      value === null ||
      value === undefined ||
      (typeof value === "number" && isNaN(value))
    ) {
      return false;
    }

    if (typeof value !== "object") {
      if (typeof value === "number" && !isNaN(value)) {
        return true;
      }
      return Boolean(value);
    }

    if (Array.isArray(value)) {
      return value.some(checkData);
    }

    return Object.entries(value)
      .filter(
        ([key]) =>
          !key.toLowerCase().includes("id") &&
          !key.toLowerCase().includes("applicant")
      )
      .some(([, val]) => checkData(val));
  };

  return checkData(obj);
}
export function checkListObjectHasData(listObject) {
  for (const element in listObject) {
    console.log("element", element);
    return checkObjectHasData(element);
  }
  return false;
}

export function checkObjectHasNull(obj) {
  const hasNull = (value) => {
    if (value === null || value === undefined || value === "") {
      return true;
    }

    if (typeof value !== "object") {
      return false;
    }

    if (Array.isArray(value)) {
      return value.some(hasNull);
    }

    return Object.entries(value)
      .filter(
        ([key]) =>
          !key.toLowerCase().includes("id") && key.toLowerCase() !== "applicant"
      )
      .some(([, val]) => hasNull(val));
  };

  return hasNull(obj);
}

export function RequiredValidationMessage(fieldName): string {
  return `${fieldName} is required`;
}

export function handleFactFindNavigate(tab, subtab): string {
  const currentUrl = window.location.pathname;
  const hasIdParam = currentUrl.includes("/");

  let cleanUrl;
  let navigateTab = tab;

  if (hasIdParam) {
    const idSubParamIndex = currentUrl.lastIndexOf("/");
    cleanUrl = currentUrl.substring(0, idSubParamIndex);
    if (!subtab) {
      // navigateTab = `${CUSTOMER_TABS.factfind}/${FACTFIND_TABS.personal}`;
    }
  } else {
    cleanUrl = currentUrl;
  }

  return `${cleanUrl}/${navigateTab}`;
}

export function comparePositions(array, idA, idB): boolean {
  const indexA = array.findIndex((item) => item.id === idA);
  const indexB = array.findIndex((item) => item.id === idB);

  return indexA !== -1 && indexB !== -1 && indexA < indexB;
}

export function isValidNumber(value) {
  return (
    typeof value === "number" &&
    !isNaN(value) &&
    value !== null &&
    value !== undefined
  );
}

export function addSpaceBeforeCapitalLetters(str) {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
}

export function shadeColor(color: string, percent: number) {

  let R = parseInt(color.substring(1,3),16);
  let G = parseInt(color.substring(3,5),16);
  let B = parseInt(color.substring(5,7),16);

  R = (R * (100 + percent) / 100);
  G = (G * (100 + percent) / 100);
  B = (B * (100 + percent) / 100);

  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;

  R = Math.round(R)
  G = Math.round(G)
  B = Math.round(B)

  let RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  let GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  let BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}