import React, { useCallback, useEffect, useState } from "react";
import Talk from "talkjs";
import useUserInfoTK from "../../hooks/useUserInfoTK";
import { Session, Inbox } from "@talkjs/react";
import { generateRandomCode, getLocalStorageElement, getUniqueStartCharacters, sortFullNames } from "../../utils/utils";
import Modal from "../common/modal/Modal";
import Button from "../common/button/Button";
import useModal from "../../hooks/useModal";
import { useForm } from "react-hook-form";
import { UserDTO } from "@dineiro/dineiro-sdk-mobile";
import toast from "react-hot-toast";
import { appIdTalkjs } from "../../environment";
import Spinner from "../common/spinner/Spinner";
import { StyledModal } from "../common/change-themes/styles-component";
import CustomerTable from "../common/customer-list/CustomersList";
import SearchIcon from "../../images/document/search-icon.svg";
import RemoveIcon from "../../images/message/remove.svg";
import useCustomers from "../../hooks/useCustomers";
import { useParams } from "react-router-dom";
import { getBrandOfficesApi } from "../../services/api-service";
import { jwtDecode } from "jwt-decode";

const MyChat: React.FC<{}> = () => {
  const {officeid} = useParams();
  const storedIdToken = getLocalStorageElement("idToken");
  const {
    customers,
    setSearch,
  } = useCustomers({
    officeId: officeid,
  });
  const [alphabet, setAlphabet] = useState([]);
  const {userId, userName, emailAddress} = useUserInfoTK();
  const [conversation, setConversation] = useState<Talk.ConversationData>(undefined);
  const [spinner, setSpinner] = useState(false);
  const [conversationSubject, setConversationSubject] = useState("");
  const [selectedParticipants, setSelectedParticipants] = useState<UserDTO[]>([]);
  const [talkJsToken, setTalkJsToken] = useState('');
  const [conversationSyncReason, setConversationSyncReason] =
    useState<'subjectChanged' | 'newConversation' | 'invitedUsers' | 'defaultConversation' | undefined>(undefined);

  const {show: showUpdateConversationSubjectModal, toggle: toggleUpdateConversationSubjectModal} = useModal();
  const {show: showCreateConversationModal, toggle: toggleCreateConversationModal} = useModal();
  const {show: showInviteUsersModal, toggle: toggleInvitedUsersModal} = useModal();
  const {
    register,
    handleSubmit,
    reset,
    formState: {},
  } = useForm();

  const syncUser = useCallback(() => {
    if (userId) {
      return new Talk.User({
        id: userId,
        name: userName,
        email: emailAddress,
        welcomeMessage: "Hi!",
        role: "default",
      });
    }
    return new Talk.User({
      id: "Temple",
      name: "userName",
      email: "emailAddress",
      welcomeMessage: "Hi!",
      role: "default",
    });
  }, [userId]);

  function updateConversationSubjectModalSubmitHandler(data: { conversationTitle: string }) {
    const conversationTitle = data.conversationTitle;
    setConversationSubject(conversationTitle);
    setConversationSyncReason('subjectChanged');
    reset();
  }

  const syncConversation = useCallback(
    (session: Talk.Session) => {
      if (conversationSyncReason === 'newConversation') {
        if (selectedParticipants.length > 0) {
          const participants = [
            session.me,
            ...selectedParticipants.map((user) => {
              return new Talk.User({
                id: user.id,
                name: `${user.firstName} ${user.lastName}`,
                email: user.email,
                role: "default",
              });
            }),
          ];
          const newConversationId = `${userId}${selectedParticipants[0] && selectedParticipants[0].id
            ? selectedParticipants[0].id
            : ""
          }${generateRandomCode()}`;

          const newConversationBuilder = session.getOrCreateConversation(newConversationId);
          if (!newConversationBuilder.custom) newConversationBuilder.custom = {};
          console.log(newConversationBuilder);
          newConversationBuilder.custom["brandOfficeId"] = officeid;

          participants.forEach((participant) => {
            newConversationBuilder.setParticipant(participant);
          });
          if (conversationSubject) newConversationBuilder.setAttributes({subject: conversationSubject});
          setConversationSubject('');
          setSelectedParticipants([]);
          toggleCreateConversationModal();
          setConversationSyncReason(undefined);
          return newConversationBuilder;
        }
        setConversationSubject('');
        setSelectedParticipants([]);
        toggleCreateConversationModal();
        setConversationSyncReason(undefined);
      } else if (conversationSyncReason === 'subjectChanged') {
        if (conversation) {
          const conversationBuilder = session.getOrCreateConversation(conversation.id);
          if (!conversationBuilder.custom) conversationBuilder.custom = {};
          if (!conversationBuilder.custom["brandOfficeId"]) {
            conversationBuilder.custom["brandOfficeId"] = officeid;
          }
          conversationBuilder.setAttributes({subject: conversationSubject});
          setConversation(undefined);
          setSelectedParticipants([]);
          toggleUpdateConversationSubjectModal();
          setConversationSyncReason(undefined);
          return conversationBuilder;
        }
        setConversation(undefined);
        setSelectedParticipants([]);
        toggleUpdateConversationSubjectModal();
        setConversationSyncReason(undefined);
      } else if (conversationSyncReason === 'invitedUsers') {
        if (conversation) {
          const conversationBuilder = session.getOrCreateConversation(conversation.id);
          // Customers are in the conversation
          const existingUserIds = Object.keys(conversation.participants);
          // New customers in the conversation
          const addingUsers = selectedParticipants.filter(s => !existingUserIds.some(i => i === s.id));
          const addingParticipants = addingUsers.map((user) => {
            return new Talk.User({
              id: user.id,
              name: `${user.firstName} ${user.lastName}`,
              email: user.email,
              role: "default",
            });
          });
          addingParticipants.forEach((participant) => {
            conversationBuilder.setParticipant(participant);
          });
          setConversation(undefined);
          setSelectedParticipants([]);
          toggleInvitedUsersModal();
          setConversationSyncReason(undefined);
          return conversationBuilder;
        }
        setConversation(undefined);
        setSelectedParticipants([]);
        toggleUpdateConversationSubjectModal();
        setConversationSyncReason(undefined);
      } else if (conversationSyncReason === 'defaultConversation') {
        const newConversationBuilder = session.getOrCreateConversation(userId);
        if (!newConversationBuilder.custom) newConversationBuilder.custom = {};
        newConversationBuilder.custom["brandOfficeId"] = officeid;
        newConversationBuilder.setParticipant(session.me);
        return newConversationBuilder;
      }
    },
    [conversationSyncReason]
  );

  function createNewChat() {
    try {
      setConversationSyncReason('newConversation');
      toast.success("Conservation addition successful.");
    } catch (e) {
      toast.error(e.message);
    } finally {
    }
  }

  const inviteChatButtonClickedHandler = () => {
    setConversationSyncReason('invitedUsers');
    toast.success("Invited users successfully");
  };

  const handleCustomerSelect = (customerId) => {
    if (selectedParticipants.includes(customerId)) {
      setSelectedParticipants(selectedParticipants.filter((id) => id !== customerId));
    } else {
      setSelectedParticipants([...selectedParticipants, customerId]);
    }
  };

  const handleRemoveSelectedParticipant = (id: string) => {
    var newSelectedParticipants = selectedParticipants.filter(t => t.id !== id);
    setSelectedParticipants(newSelectedParticipants);
  };

  const handleSearchChange = (e) => {
    var value = e.target.value;
    if (value.length >= 3 || value.length === 0) {
      setSearch(value);
    }
  };

  const refreshTalkJsToken = (officeId: number, userId: string) => {
    const storedTalkJsToken = getLocalStorageElement('talkJsToken');
    const decodedToken = storedTalkJsToken ? jwtDecode(storedTalkJsToken) : undefined;
    const isGetNewOne = !decodedToken || Date.now() >= decodedToken.exp * 1000 - 10 * 60 * 1000 || decodedToken.sub !== userId;
    if (!isGetNewOne) {
      setTalkJsToken(storedTalkJsToken);
    } else {
      const storedIdToken = getLocalStorageElement("idToken");
      const brandApi = getBrandOfficesApi(storedIdToken);
      brandApi.brandOfficeTalkJsTokenPost({id: officeId}).then(rep => {
        localStorage.setItem('talkJsToken', rep.data);
        setTalkJsToken(rep.data);
      });
    }
  };

  useEffect(() => {
    setSpinner(true);
    if (customers.length > 0) {
      const getAlphabet = getUniqueStartCharacters(customers);
      setAlphabet(getAlphabet);
    }
    setTimeout(async () => {
      setSpinner(false);
    }, 2000);
  }, [customers]);

  useEffect(() => {
    if (!officeid || !userId) return;
    refreshTalkJsToken(+officeid, userId);
    const setIntervalId = setInterval(() => {
      refreshTalkJsToken(+officeid, userId);
    }, 5000);
    return () => clearInterval(setIntervalId);
  }, [officeid, userId]);

  useEffect(() => {
    setConversationSyncReason('defaultConversation')
  }, []);

  // Check isAddedOfficeManagers and call api if needed
  const talkJsOnMessage = (m: Talk.Message) => {
    if (m.senderId === userId && m.conversation.custom['isAddedOfficeManagers'] !== 'true') {
      console.log('Turn isAddedOfficeManagers on');
      const brandApi = getBrandOfficesApi(storedIdToken);
      brandApi.brandOfficeTalkJsConversationsParticipantsAddOfficeManagersPostRaw({
        id: +officeid,
        conversationId: m.conversation.id,
      }).then(rep => {
        console.log('Turned isAddedOfficeManagers on successfully');
      }).catch(reason => {
        console.log('Failed to Turn isAddedOfficeManagers on');
      });
    }
  };

  return (
    <div>
      <div className="flex justify-center">
        {talkJsToken &&
          <Session appId={appIdTalkjs} syncUser={syncUser} token={talkJsToken} onMessage={talkJsOnMessage}>
            <div className="relative">
              <div className="w-[835px] absolute max-w-[840px] h-[700px] overflow-hidden">
                <Inbox
                  syncConversation={syncConversation}
                  style={{
                    width: "100%",
                    maxWidth: 840,
                    height: 700,
                    borderRadius: "14px",
                  }}
                  onCustomConversationAction={(e) => {
                    if (e.action === "editTitleOrImage") {
                      setConversation(e.conversation);
                      toggleUpdateConversationSubjectModal();
                    } else if (e.action === "inviteUsers") {
                      setConversation(e.conversation);
                      toggleInvitedUsersModal();
                    } else if (e.action === "createNewChat") {
                      toggleCreateConversationModal();
                    }
                  }}
                  loadingComponent={<span>Loading..</span>}
                />
              </div>
              <div className="flex gap-[15px]">
                <div
                  className="w-[400px] max-w-[420px] h-[700px] bg-white/20 rounded-xl mx-auto overflow-hidden"></div>
                <div
                  className="w-[420px] max-w-[450px] h-[638px] bg-white/20 rounded-b-xl mx-auto mt-auto overflow-hidden"></div>
              </div>
            </div>
          </Session>}
        <Modal
          show={showUpdateConversationSubjectModal}
          toggle={toggleUpdateConversationSubjectModal}
          content={
            <div className="rounded-xl px-2 py-4">
              <div className="flex flex-col items-center">
                <div className="w-full">
                  <form
                    onSubmit={handleSubmit(updateConversationSubjectModalSubmitHandler)}
                    className="flex justify-center items-center gap-2"
                  >
                    <div className="w-2/3 mb-4">
                      <label
                        className=" font-semibold"
                        htmlFor="conversationTitle"
                      >
                        New Conversation title:
                      </label>
                      <input
                        type="text"
                        className="form-control rounded-md"
                        id="conversationTitle"
                        placeholder="Title"
                        required
                        {...register("conversationTitle")}
                      />
                    </div>
                    <div className="justify-center">
                      <Button
                        w="100px"
                        h="40px"
                        px="16px "
                        py="8px"
                        borderRadius="0.375rem"
                        fontSize="12px"
                        lineHeight="16px"
                      >
                        Submit
                      </Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          }
        ></Modal>
        <Modal
          show={showCreateConversationModal}
          toggle={() => {
            setConversationSubject('');
            setSelectedParticipants([]);
            toggleCreateConversationModal();
            setConversationSyncReason(undefined);
          }}
          content={
            <StyledModal>
              <div className="grid grid-cols-2 h-[780px]">
                <div className="h-full px-[20px] pb-[20px] pt-[30px] flex flex-col">

                  <div className="h-[20px] mt-2">
                    <p className="header-h3 font-bold"
                       style={{lineHeight: "20px", fontSize: "25px"}}>Create New Chat</p>
                  </div>

                  <div className="min-w-[200px] max-w-lg flex justify-center relative mt-4 mb-3">
                    <input
                      type="text"
                      placeholder="Search Customers"
                      className="bg-black/20 w-full h-11 pl-10 pr-3 rounded-lg text-sm border-none focus:ring-white"
                      onChange={(e) => handleSearchChange(e)}
                    />
                    <div
                      className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400 ">
                      <img src={SearchIcon} alt=""/>
                    </div>
                  </div>

                  <CustomerTable customers={sortFullNames(customers)}
                                 selectedCustomers={selectedParticipants} alphabet={alphabet}
                                 tableHeight={"560px"}
                                 handleCustomerSelect={handleCustomerSelect}></CustomerTable>

                  <div className="mt-auto px-[10px] text-center">
                    <button
                      name="saveLater"
                      type="button"
                      className="h-[36px] w-[157px] px-[18px] border font-semibold border-white rounded-md uppercase"
                      style={{fontSize: '12px', fontWeight: '700', fontFamily: 'Poppins'}}
                      onClick={() => setSelectedParticipants(customers)}
                    >
                      SELECT ALL CLIENTS
                    </button>
                    <button
                      className="underline w-[157px]"
                      onClick={() => setSelectedParticipants([])}
                      style={{fontSize: '12px', fontWeight: '700', fontFamily: 'Poppins'}}
                    >CLEAR SELECTION
                    </button>
                  </div>
                </div>
                <div className="h-full bg-black/20 flex flex-col px-[20px] pb-[20px] pt-[30px]">
                  <div className="mt-[28px]">
                                        <span className="header-h3 font-bold upercase "
                                              style={{lineHeight: '16px', fontSize: '12px'}}>CHAT TOPIC</span>
                    <input
                      type="text"
                      className="bg-black/20 w-full h-11 rounded-lg text-sm border-none focus:ring-white"
                      onChange={(e) => {
                        if (e.target.value !== "" && e.target.value !== null) setConversationSubject(e.target.value)
                      }}
                    />
                  </div>
                  <p className="font-bold mt-4">Selected participants : </p>
                  <div className="w-full max-h-[510px] overflow-auto px-2">
                    <table className="w-full text-sm">
                      {sortFullNames(selectedParticipants).map((user) => {
                        return (
                          <tr>
                            <td className="py-2">{user.firstName + " " + user.lastName}</td>
                            <td>
                              <img src={RemoveIcon} alt=""
                                   className="float-right cursor-pointer"
                                   onClick={() => handleRemoveSelectedParticipant(user.id)}/>
                            </td>
                          </tr>
                        )
                      })}
                    </table>
                  </div>
                  <div className="mt-auto">
                    <Button
                      h="36px"
                      px="16px "
                      py="8px"
                      borderRadius="0.375rem"
                      fontSize="14px"
                      lineHeight="20px"
                      onClick={() => createNewChat()}
                    >
                      START CONVERSATION
                    </Button>
                  </div>
                </div>
              </div>
            </StyledModal>
          }
          width={"w-[840px]"}
          height={"h-[780px]"}
        ></Modal>

        <Modal
          show={showInviteUsersModal} width={"w-[840px]"} height={"h-[780px]"}
          toggle={() => {
            setConversation(undefined);
            setSelectedParticipants([]);
            toggleInvitedUsersModal();
            setConversationSyncReason(undefined);
          }}
          content={
            <StyledModal>
              <div className="grid grid-cols-2 h-[780px]">
                <div className="h-full px-[20px] pb-[20px] pt-[30px] flex flex-col">

                  <div className="h-[20px] mt-2">
                    <p className="header-h3 font-bold"
                       style={{lineHeight: "20px", fontSize: "25px"}}>Invite To Chat</p>
                  </div>

                  <div className="min-w-[200px] max-w-lg flex justify-center relative mt-4 mb-3">
                    <input
                      type="text"
                      placeholder="Search Customers"
                      className="bg-black/20 w-full h-11 pl-10 pr-3 rounded-lg text-sm border-none focus:ring-white"
                      onChange={(e) => handleSearchChange(e)}
                    />
                    <div
                      className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none text-gray-400 ">
                      <img src={SearchIcon} alt=""/>
                    </div>
                  </div>

                  <CustomerTable customers={sortFullNames(customers)}
                                 selectedCustomers={selectedParticipants} alphabet={alphabet}
                                 tableHeight={"560px"}
                                 handleCustomerSelect={handleCustomerSelect}></CustomerTable>

                  <div className="mt-auto px-[10px] text-center">
                    <button
                      name="saveLater"
                      type="button"
                      className="h-[36px] w-[157px] px-[18px] border font-semibold border-white rounded-md uppercase"
                      style={{fontSize: '12px', fontWeight: '700', fontFamily: 'Poppins'}}
                      onClick={() => setSelectedParticipants(customers)}
                    >
                      SELECT ALL CLIENTS
                    </button>
                    <button
                      className="underline w-[157px]"
                      onClick={() => setSelectedParticipants([])}
                      style={{fontSize: '12px', fontWeight: '700', fontFamily: 'Poppins'}}
                    >CLEAR SELECTION
                    </button>
                  </div>
                </div>
                <div className="h-full bg-black/20 flex flex-col px-[20px] pb-[20px] pt-[30px]">
                  <div className="mt-[28px]"></div>
                  <p className="font-bold mt-4">Selected participants:</p>
                  <div className="w-full max-h-[510px] overflow-auto px-2">
                    <table className="w-full text-sm">
                      {sortFullNames(selectedParticipants).map((user) => {
                        return (
                          <tr>
                            <td className="py-2">{user.firstName + " " + user.lastName}</td>
                            <td>
                              <img src={RemoveIcon} alt=""
                                   className="float-right cursor-pointer"
                                   onClick={() => handleRemoveSelectedParticipant(user.id)}/>
                            </td>
                          </tr>
                        )
                      })}
                    </table>
                  </div>
                  <div className="mt-auto">
                    <Button
                      h="36px"
                      px="16px "
                      py="8px"
                      borderRadius="0.375rem"
                      fontSize="14px"
                      lineHeight="20px"
                      onClick={() => {
                        inviteChatButtonClickedHandler();
                      }}
                    >
                      INVITE TO CHAT
                    </Button>
                  </div>
                </div>
              </div>
            </StyledModal>
          }></Modal>
      </div>
      {spinner && <Spinner></Spinner>}
    </div>
  );
};

export default MyChat;
