import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import EditorComponent from "../../components/common/editor/EditorComponent";
import ContentTable from "../../components/content-table/ContentTable";
import Button from "../../components/common/button/Button";
import { useParams } from "react-router-dom";
import { getLocalStorageElement, shadeColor } from "../../utils/utils";
import { getBrandOfficesApi } from "../../services/api-service";
import toast, { Toaster } from "react-hot-toast";
import useBrandContents from "../../hooks/useBrandContents";
import Spinner from "../../components/common/spinner/Spinner";
import PendingReleases from "../../components/content-table/PendingRelease";
import SearchIcon from "../../images/home/search-icon.svg";
import TimesCircle from "../../images/times-circle.svg";
import { TagDTO } from "@dineiro/dineiro-sdk-mobile";

const AdminContent = () => {
  const storedIdToken = getLocalStorageElement("idToken");
  const [reloadContent, setReloadContent] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [imageName, setImageName] = useState("");
  const [primaryImage, setPrimaryImage] = useState<Blob>();
  const {officeid} = useParams();

  const {shouldRefetch, contents, pendingContents, refetch} =
    useBrandContents({
      officeId: officeid,
    });
  const [spinner, setSpinner] = useState(false);
  const [officeTags, setOfficeTags] = useState<TagDTO[]>([]);
  const [selectedTags, setSelectedTags] = useState<TagDTO[]>([]);
  const [searchTagsText, setSearchTagsText] = useState<string>('');
  const [styledComponentProps, setStyledComponentProps] = useState({buttonColor: '#78BE20', topLeftColor: '#0E89AF'});
  const [isSearchTagsActive, setIsSearchTagsActive] = useState(false);
  const searchTagsElementRef = useRef(null);
  const searchTagsPopoverElementRef = useRef(null);

  // Load office tags
  useEffect(() => {
    if (officeid) {
      const storedIdToken = getLocalStorageElement("idToken");
      const brandOfficesApi = getBrandOfficesApi(storedIdToken);
      const storedProps = JSON.parse(localStorage.getItem("styledComponentProps"));
      if (storedProps) {
        setStyledComponentProps(storedProps);
      }
      const toastId = toast.loading('Loading ...');
      brandOfficesApi.brandOfficeTagsGet({id: +officeid}).then(rep => {
        setOfficeTags(rep.data);
      }).finally(() => {
        toast.dismiss(toastId);
      });
    }
  }, [officeid]);

  useEffect(() => {
    document.onmouseup = (ev) => {
      ev.stopPropagation();
      let target: any = ev.target;
      while (target) {
        if (target !== searchTagsElementRef.current && target !== searchTagsPopoverElementRef.current) {
          target = target.parentElement;
        } else {
          return;
        }
      }
      setIsSearchTagsActive(false);
    };
  }, []);

  const handleSentContent = async () => {
    if (selectedTags.length > 0) {
      if (title === "") {
        toast.error("Please enter a title for the content");
      } else if (primaryImage === null || primaryImage === undefined) {
        toast.error("Please upload the primary image");
      } else if (content === "" || content === "<p></p>") {
        toast.error("Please enter the content");
      } else {
        const userReportApi = getBrandOfficesApi(storedIdToken);
        try {
          await userReportApi
            .brandOfficeArticlesPost({
              id: parseInt(officeid),
              title: title,
              primaryImage: primaryImage,
              content: content,
              tagIds: selectedTags.map(t => t.id),
            })
            .then((respone) => {
              refetch(!shouldRefetch);
              setTitle("");
              setContent("");
              setPrimaryImage(null);
              setImageName("");
              setReloadContent(!reloadContent);
              setSelectedTags([]);
            });
          toast.success("Sent content successful");
        } catch (error) {
          error.response.json().then((x: any) => {
            toast.error(x.errors[""][0]);
          });
        }
      }
    } else toast.error("Please select user to sent!");
  };

  const handleRecallMessage = (contentiId) => {
    if (contentiId) {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      toast.promise(
        userReportApi
          .brandOfficeArticlesStatusTogglePost({
            id: parseInt(officeid),
            officeArticleId: contentiId,
          })
          .then((respone) => {
            refetch(!shouldRefetch);
          }),
        {
          loading: "Saving...",
          success: <b>Recall content successful.</b>,
          error: <b>Failed to recall content.</b>,
        }
      );
    } else toast.error("Please select user to sent!");
  };

  const handleApproveContent = (contentiId) => {
    if (contentiId) {
      const userReportApi = getBrandOfficesApi(storedIdToken);
      toast.promise(
        userReportApi
          .brandOfficeArticlesIsApprovedPost({
            id: parseInt(officeid),
            officeArticleId: contentiId,
          })
          .then((respone) => {
            refetch(!shouldRefetch);
          }),
        {
          loading: "Saving...",
          success: <b>Recall content successful.</b>,
          error: <b>Failed to recall content.</b>,
        }
      );
    } else toast.error("Please select user to sent!");
  };

  const hiddenFileInput = useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.includes("image")) {
      const uploadedImage = new Blob([file], {
        type: file.type,
      });
      setImageName(file.name);
      setPrimaryImage(uploadedImage);
    } else {
      toast.error("Upload failed. Please upload image only");
    }
  };

  useLayoutEffect(() => {
    const storedProp = localStorage.getItem("brandOfficeName");
    if (storedProp) document.title = `Contents - ${storedProp}`;
  }, []);

  const searchTagItemClickedHandler = (id: number) => {
    if (selectedTags.some(t => t.id === id)) {
      setSelectedTags(selectedTags.filter(t => t.id !== id));
    } else {
      setSelectedTags([...selectedTags, officeTags.find((t) => t.id === id)]);
    }
  };

  const allClientsButtonClickedHandler = () => {
    if (selectedTags.length === officeTags.length) {
      setSelectedTags([]);
    } else {
      setSelectedTags([...officeTags]);
    }
  };

  return (
    <div className="items-center justify-center p-3">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">DINEIRO ADMIN</h6>
        <span className=" text-5xl text-center font-bold">
          Content Manager.
        </span>
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center h-full w-full">
        <div className="w-2/3 mt-5">
          <h3 className="text-xl font-bold">Choose Recipients</h3>
          <div className="grid grid-cols-2 gap-2">
            <div className="relative">
              <div className="flex text-center mb-3">
                <input type="text" placeholder="Search Tags"
                       className="bg-black/20 w-full h-11 rounded-l-lg text-sm border-none focus:ring-white"
                       onChange={(v) => setSearchTagsText(v.target.value)}
                       onFocus={() => setIsSearchTagsActive(true)}
                       ref={searchTagsElementRef}
                />
                <Button borderBottomRightRadius="8px" borderTopRightRadius="8px" px="14px" onClick={(e: Event) => {}}>
                  <img src={SearchIcon} alt=""/>
                </Button>
              </div>
              <div className={`rounded py-3 max-h-[400px] overflow-auto absolute w-100 z-3 ${isSearchTagsActive ? '' : 'hidden'}`}
                   style={{backgroundColor: shadeColor(styledComponentProps.topLeftColor, -30)}}
                   ref={searchTagsPopoverElementRef}
              >
                {officeTags.filter(t => !searchTagsText
                  || searchTagsText === ''
                  || t.name.toLowerCase().startsWith(searchTagsText.toLowerCase()))
                  .map(tag => selectedTags.some(u => u.id === tag.id) ?
                    <div key={tag.id} className="px-3 py-1 cursor-pointer"
                         style={{backgroundColor: styledComponentProps.buttonColor}}
                         onClick={() => searchTagItemClickedHandler(tag.id)}
                    >{tag.name}</div> :
                    <div key={tag.id} className="px-3 py-1 cursor-pointer"
                         onClick={() => searchTagItemClickedHandler(tag.id)}
                    >{tag.name}</div>)}
              </div>
            </div>
          </div>
          <div className="flex">
            <div>
              <div className={`inline-block px-[10px] py-[8px] rounded text-sm w-[90px] text-center cursor-pointer ${officeTags.length === selectedTags.length ? 'bg-black/60' : 'bg-black/20'}`}
                   onClick={e => allClientsButtonClickedHandler()}
              >
                All Clients
              </div>
            </div>
            <div className="w-[50px] flex justify-content-center align-self-baseline">
              <div className="w-0.5 h-[48px] bg-white/20 relative bottom-1"></div>
            </div>
            <div className="clearfix">
              {
                selectedTags.map((tag) => <>
                  <div className="float-left inline-block px-[10px] py-[8px] bg-black/20 rounded text-sm mr-1 mb-1">
                    {tag.name} &nbsp;&nbsp;
                    <img src={TimesCircle} alt=""
                         className="inline cursor-pointer"
                         onClick={() => searchTagItemClickedHandler(tag.id)}/>
                  </div>
                </>)
              }
            </div>
          </div>
        </div>
        <div className="w-2/3 my-5">
          <div className="flex flex-col">
            <span className="mb-2 text-xl font-semibold">Create Content</span>
            <div className="w-full space-y-2 mb-2">
              <div className="grid grid-cols-2 gap-2">
                <input type="text" placeholder="Message Headline" value={title}
                       className="bg-black/20 w-full h-11 rounded placeholder-gray-200 text-sm border-none focus:ring-white"
                       onChange={(e) => setTitle(e.target.value)}/>
                <div className="flex">
                  <input type="text" placeholder="Primary Image"
                         className="bg-black/20 w-full h-11 rounded-l-md placeholder-gray-200 text-sm border-none focus:ring-white"
                         value={imageName} onChange={(e) => e}/>
                  <Button h="40px" w="1/5" px="12px" borderBottomRightRadius="8px" borderTopRightRadius="8px"
                          onClick={handleClick}>
                    UPLOAD
                  </Button>
                  <input type="file" onChange={handleChange} ref={hiddenFileInput} style={{display: "none"}}
                         accept="image/*"/>
                </div>
              </div>
            </div>
            <div className=" w-full my-3">
              <EditorComponent
                setContent={setContent}
                reloadContent={reloadContent}
              />
            </div>
            <div className=" my-2">
              <Button
                h="40px"
                w="1/5"
                px="12px"
                borderRadius="6px"
                onClick={() => handleSentContent()}
              >
                SEND MESSAGE
              </Button>
            </div>
          </div>
        </div>
        <div className="w-2/3 my-5">
          <PendingReleases
            contents={pendingContents}
            handleApproveContent={handleApproveContent}
          />
        </div>
        <div className="w-2/3 my-5">
          <ContentTable
            contents={contents}
            handleRecallMessage={handleRecallMessage}
          />
        </div>
      </div>
      {spinner && <Spinner></Spinner>}
      <Toaster position="top-center" reverseOrder={false}/>
    </div>
  );
};

export default AdminContent;
