import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { getBrandOfficesApi } from "../../services/api-service";
import { getLocalStorageElement } from "../../utils/utils";
import { FolderTemplateDTO, ResponseError, TagDTO, UserDTO } from "@dineiro/dineiro-sdk-mobile";
import Button from "../../components/common/button/Button";
import { useForm } from "react-hook-form";
import { VALIDATION } from "../../constants/constants";
import SearchIcon from "../../images/home/search-icon.svg";
import PlusCircle from "../../images/plus-circle.svg";
import TimesCircle from "../../images/times-circle.svg";
import toast, { Toaster } from "react-hot-toast";
import { LoggedInUser, parseToken } from "../../utils/token-service";

const CustomerProfilePage = () => {
  const { officeid, id } = useParams();
  const [loggedInUser, setLoggedInUser] = useState<LoggedInUser | undefined>(undefined);
  const initState: {
    officeTags: TagDTO[];
    userProfile: UserDTO | null;
    brokers: UserDTO[];
    folderTemplates: FolderTemplateDTO[];
    creatingFolderTemplate: {
      name: string;
      folders: string[];
    },
    isAddTagOpened: boolean;
    buttonColor: string;
    searchTagsText: string;
  } = {
    officeTags: [],
    userProfile: null,
    brokers: [],
    folderTemplates: [],
    creatingFolderTemplate: {
      name: '',
      folders: [''],
    },
    isAddTagOpened: false,
    buttonColor: 'rgba(120, 190, 32, 1)',
    searchTagsText: '',
  };
  const [state, setState] = useState(initState);
  const [isCreateNewTemplateOpened, setIsCreateNewTemplateOpened] = useState(false);
  const profileForm = useForm();
  const folderTemplateForm = useForm();
  const newTagNameElementRef = useRef();

  useEffect(() => {
    if (officeid && id) {
      const storedIdToken = getLocalStorageElement("idToken");
      const loggedInUser = parseToken(storedIdToken);
      setLoggedInUser(loggedInUser);
      const brandOfficesApi = getBrandOfficesApi(storedIdToken);
      const storedProps = JSON.parse(localStorage.getItem("styledComponentProps"));
      const buttonColor = storedProps?.buttonColor ? storedProps.buttonColor : "rgba(120, 190, 32, 1)";
      // Get all brokers if needed
      const toastId = toast.loading('Loading ...');
      let getOfficeBrokersPromise: Promise<UserDTO[]> = Promise.resolve([]);
      if (loggedInUser?.roles.some(r => r === 'office-manager')) {
        getOfficeBrokersPromise = brandOfficesApi.brandOfficeBrokersGet({ id: +officeid }).then(rep => rep.data);
      }

      getOfficeBrokersPromise.then(brokers => {
        setState(prevState => {
          return {
            ...prevState,
            brokers: brokers,
          };
        });
        return brokers;
      }).then(brokers => {
        // Get all office tags and customer details
        Promise.all([
          brandOfficesApi.brandOfficeTagsGet({ id: +officeid }),
          brandOfficesApi.brandOfficeCustomersDetailsGet({ id: +officeid, userId: id }),
          brandOfficesApi.brandOfficeFolderTemplatesGet({ id: +officeid }),
        ]).then(reps => {
          const tags = reps[0].data.sort((a, b) => a.name.localeCompare(b.name));
          const user = reps[1].data;
          const folderTemplates = reps[2].data;
          const storedProp = localStorage.getItem("brandOfficeName");
          if (storedProp) document.title = `${user.firstName} ${user.lastName} - ${storedProp}`;
          profileForm.setValue('id', user.id);
          profileForm.setValue('firstName', user.firstName);
          profileForm.setValue('lastName', user.lastName);
          profileForm.setValue('phoneNumber', user.phoneNumber);
          profileForm.setValue('email', user.email);
          profileForm.setValue('brokerId', user.brokerId ? user.brokerId : '');
          setState(prevState => {
            return {
              ...prevState,
              officeTags: tags,
              userProfile: user,
              folderTemplates: folderTemplates,
              buttonColor: buttonColor,
            };
          });
        });
      }).finally(() => {
        toast.dismiss(toastId);
      });
    }
  }, [officeid, id]);

  useEffect(() => {
    folderTemplateForm.register('folders', { required: 'At least one folder required' });
  }, [folderTemplateForm]);

  const personalDetailsFormSubmitHandler = (data: any) => {
    console.log('personalDetailsFormSubmitHandler');
    console.log(data);
    const storedIdToken = getLocalStorageElement("idToken");
    const brandOfficesApi = getBrandOfficesApi(storedIdToken);
    const toastId = toast.loading('Updating customer profile');
    brandOfficesApi.brandOfficeCustomersPut({
      id: +officeid,
      userId: data.id,
      brandOfficeCustomersPutViewModel: {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        brokerId: data.brokerId === '' ? undefined : data.brokerId,
        phoneNumber: data.phoneNumber,
      },
    }).then(rep => {
      toast.dismiss(toastId);
      toast.success('Update customer profile successfully');
    });
  };

  const removeCustomerTagHandler = (tagId: number) => {
    setState(prevState => {
      return {
        ...prevState,
        userProfile: {
          ...prevState.userProfile,
          userTags: prevState.userProfile.userTags.filter(ut => ut.tagId !== tagId),
        }
      };
    });
  };

  const toggleCustomerTagHandler = (tagId: number) => {
    const isRemoving = state.userProfile.userTags.some(ut => ut.tagId === tagId);
    if (isRemoving) {
      setState(prevState => {
        return {
          ...prevState,
          userProfile: {
            ...prevState.userProfile,
            userTags: prevState.userProfile.userTags.filter(ut => ut.tagId !== tagId),
          }
        };
      });
    } else {
      setState(prevState => {
        return {
          ...prevState,
          userProfile: {
            ...prevState.userProfile,
            userTags: [...state.userProfile.userTags, { userId: id, tagId: tagId }],
          }
        };
      });
    }
  };

  const searchTagsTextChangedHandler = (text: string) => {
    setState(prevState => {
      return {
        ...prevState,
        searchTagsText: text,
      };
    })
  };

  const saveCustomerTagsButtonClickedHandler = () => {
    const storedIdToken = getLocalStorageElement("idToken");
    const brandOfficesApi = getBrandOfficesApi(storedIdToken);
    const toastId = toast.loading('Updating customer tags ...');
    brandOfficesApi.brandOfficeCustomersTagsPost({
      id: +officeid,
      userId: id,
      brandOfficeCustomersTagsPostViewModel: {
        tagIds: state.userProfile.userTags.map(ut => ut.tagId)
      }
    }).then(rep => {
      toast.dismiss(toastId);
      toast.success('Updated customer tags successfully');
    });
  };

  const newTagProcessHandler = (elm) => {
    if (elm.value) {
      if (state.officeTags.some(t => t.name.toLowerCase() === elm.value.toLowerCase())) {
        toast.error("The creating tag is already existed");
      } else {
        // Create tag
        const storedIdToken = getLocalStorageElement("idToken");
        const brandOfficesApi = getBrandOfficesApi(storedIdToken);
        const toastId = toast.loading('Creating tag');
        brandOfficesApi.brandOfficeTagsPost({
          id: +officeid,
          brandOfficeTagsPostViewModel: {
            name: elm.value
          },
        }).then(rep => {
          toast.dismiss(toastId);
          toast.success('Created tag successfully');
          elm.value = '';
          const tag = rep.data;
          if (!state.officeTags.some(t => t.id === tag.id)) {
            setState(prevState => {
              return {
                ...prevState,
                officeTags: [...prevState.officeTags, tag].sort((a, b) => a.name.localeCompare(b.name)),
              }
            });
          }
        });
      }
    }
  };

  const newTagInputKeyupHandler = (e) => {
    if (e.key === 'Enter') newTagProcessHandler(e.target);
  };

  // Folder template event handlers
  const folderTemplateSelectBoxChangedHandler = (e) => {
    const folderTemplateId = e.target.value;
    const storedIdToken = getLocalStorageElement("idToken");
    const brandOfficesApi = getBrandOfficesApi(storedIdToken);
    const toastId = toast.loading('Updating folder template');
    brandOfficesApi.brandOfficeCustomersFolderTemplatePut({
      id: +officeid,
      userId: id,
      brandOfficeCustomersFolderTemplatePutViewModel: {
        folderTemplateId: folderTemplateId,
      }
    }).then(rep => {
      setState(prevState => {
        return {
          ...prevState,
          userProfile: {
            ...prevState.userProfile,
            folderTemplateId: folderTemplateId,
          }
        };
      });
      toast.dismiss(toastId);
      toast.success('Updated folder template successfully');
    }).catch(reason => console.log(reason));
  };

  const createNewTemplateFormSubmittedHandler = (e) => {
    folderTemplateForm.setValue('folders', state.creatingFolderTemplate.folders.filter(f => f).join(','));
    folderTemplateForm.trigger(['name', 'folders']).then(v => {
      if (!v) return;
      const storedIdToken = getLocalStorageElement("idToken");
      const brandOfficesApi = getBrandOfficesApi(storedIdToken);
      const toastId = toast.loading('Creating new folder template');
      brandOfficesApi.brandOfficeFolderTemplatesPost({
        id: +officeid,
        brandOfficeFolderTemplatesPostViewModel: {
          name: state.creatingFolderTemplate.name,
          folders: state.creatingFolderTemplate.folders.filter(f => f),
        }
      }).then(rep => {
        toast.success('Created folder template successfully');
        folderTemplateForm.reset();
        setIsCreateNewTemplateOpened(false);
        setState(prevState => {
          return {
            ...prevState,
            folderTemplates: [...prevState.folderTemplates, rep.data],
            creatingFolderTemplate: {
              name: '',
              folders: [''],
            }
          }
        });
      }).catch(reason => {
        const ex: ResponseError = reason;
        ex.response.json().then(error => {
          toast.error(error.detail);
        });
      }).finally(() => {
        toast.dismiss(toastId);
      });
    });
    e.preventDefault();
  };

  const folderNameRemoveButtonClickedHandler = (index: number) => {
    if (state.creatingFolderTemplate.folders.length === 1) return;
    const removingFolder = state.creatingFolderTemplate.folders[index];
    console.log(removingFolder);
    const folders = state.creatingFolderTemplate.folders.filter(f => f !== removingFolder);
    console.log(folders);
    setState(prevState => {
      return {
        ...prevState,
        creatingFolderTemplate: {
          ...prevState.creatingFolderTemplate,
          folders: folders,
        }
      };
    });
  };

  const customerFullName = `${state.userProfile?.firstName ? state.userProfile?.firstName : ''} ${state.userProfile?.lastName ? state.userProfile?.lastName : ''}`.trim();
  return (
    <div className="items-center justify-center p-3">
      <div className="p-4 text-center mb-10">
        <h6 className="text-sm">DINEIRO ADMIN</h6>
        {customerFullName &&
          <span className=" text-5xl text-center font-bold">{customerFullName}</span>
        }
        <div className="flex items-center justify-center mt-2">
          <div className="h-1 bg-yellow-500 w-24"></div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4 w-2/3 mx-auto">
        <div>
          <h3 className="text-3xl header-h3 font-bold">Personal Details</h3>
          <div className="h-1 bg-yellow-500 w-24 mb-4"></div>
          <form onSubmit={profileForm.handleSubmit(personalDetailsFormSubmitHandler)}>
            <input type="hidden" {...profileForm.register('id')} />
            <div className="mb-4">
              <label htmlFor="firstName" className="block text-sm font-bold text-white uppercase">
                First Name
              </label>
              <input type="text" id="firstName"
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                {...profileForm.register("firstName", { required: VALIDATION.required.firstName })}
              />
              {profileForm.formState.errors.firstName &&
                <span
                  className="text-red-400 text-sm font-bold">{profileForm.formState.errors.firstName.message.toString()}</span>}
            </div>
            <div className="mb-4">
              <label htmlFor="lastName" className="block text-sm font-bold text-white uppercase">
                Last Name
              </label>
              <input type="text" id="lastName"
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                {...profileForm.register("lastName", { required: VALIDATION.required.lastName })}
              />
              {profileForm.formState.errors.lastName &&
                <span
                  className="text-red-400 text-sm font-bold">{profileForm.formState.errors.lastName.message.toString()}</span>}
            </div>
            <div className="mb-4">
              <label htmlFor="phoneNumber" className="block text-sm font-bold text-white uppercase">
                Phone Number
              </label>
              <input type="text" id="phoneNumber"
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                {...profileForm.register("phoneNumber", { required: VALIDATION.required.phone })}
              />
              {profileForm.formState.errors.phoneNumber &&
                <span
                  className="text-red-400 text-sm font-bold">{profileForm.formState.errors.phoneNumber.message.toString()}</span>}
            </div>
            <div className="mb-4">
              <label htmlFor="email" className="block text-sm font-bold text-white uppercase">
                Email
              </label>
              <input type="email" id="email"
                className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                {...profileForm.register("email", { required: VALIDATION.required.email })}
              />
              {profileForm.formState.errors.email &&
                <span
                  className="text-red-400 text-sm font-bold">{profileForm.formState.errors.email.message.toString()}</span>}
            </div>
            {loggedInUser?.roles.some(r => r === 'office-manager') &&
              <div className="mb-3">
                <label htmlFor="brokerId" className="block text-sm font-bold text-white uppercase">Broker</label>
                <select id="brokerId"
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  {...profileForm.register("brokerId")}
                >
                  <option value=''>Please Select One</option>
                  {
                    state.brokers.map((broker, index) => <option value={broker.id}
                      key={index}>{`${broker.firstName} ${broker.lastName}`.trim()}</option>)
                  }
                </select>
              </div>}
            {
              !loggedInUser?.roles.some(r => r === 'office-manager') &&
              <input type="hidden" {...profileForm.register("brokerId")} />
            }
            <Button borderRadius="6px" w="83px" h="45px">Save</Button>
          </form>
        </div>
        <div>
          <h3 className="text-3xl header-h3 font-bold">Customer Tagging</h3>
          <div className="h-1 bg-yellow-500 w-24 mb-4"></div>
          <div className="mb-4 clearfix">
            {state.userProfile?.userTags.map(ut => <>
              <div className="inline-block float-left p-[10px] bg-black/20 mr-1 mb-1 rounded text-sm" key={ut.id}>
                {state.officeTags.find(t => t.id === ut.tagId).name}
                &nbsp;&nbsp;
                <img src={TimesCircle} alt=""
                  className="inline cursor-pointer"
                  onClick={() => {
                    removeCustomerTagHandler(ut.tagId)
                  }}
                />
              </div>
            </>)}
          </div>
          {!state.isAddTagOpened && <Button borderRadius="6px" w="161px" h="45px" onClick={() => {
            setState(prevState => {
              return {
                ...prevState,
                isAddTagOpened: !prevState.isAddTagOpened,
              };
            })
          }}>ADD MORE TAGS</Button>}
          {state.isAddTagOpened && <>
            <div className="flex text-center mb-3">
              <input
                type="text"
                placeholder="Search Tags"
                className="bg-black/20 w-full h-11 rounded-l-lg text-sm border-none focus:ring-white"
                onChange={(v) => {
                  searchTagsTextChangedHandler(v.target.value);
                }}
              />
              <Button
                borderBottomRightRadius="8px"
                borderTopRightRadius="8px"
                px="14px "
                onClick={(e: Event) => {
                }}
              >
                <img src={SearchIcon} alt="" />
              </Button>
            </div>
            <div className="rounded-t bg-black/20 py-3 max-h-[400px] overflow-auto">
              {state.officeTags.filter(t => !state.searchTagsText
                || state.searchTagsText === ''
                || t.name.toLowerCase().startsWith(state.searchTagsText.toLowerCase()))
                .map(tag => state.userProfile.userTags.some(u => u.tagId === tag.id) ?
                  <div key={tag.id} className="px-3 py-1 cursor-pointer"
                    style={{ backgroundColor: state.buttonColor }}
                    onClick={() => toggleCustomerTagHandler(tag.id)}
                  >{tag.name}</div> :
                  <div key={tag.id} className="px-3 py-1 cursor-pointer"
                    onClick={() => toggleCustomerTagHandler(tag.id)}
                  >{tag.name}</div>)}
            </div>
            <div className="rounded-b bg-black/20 mb-4">
              <div className="flex text-center mb-3 relative">
                <input
                  type="text"
                  placeholder="New Tag Name"
                  className="bg-black/20 w-full h-11 rounded-b text-sm border-none"
                  onChange={(v) => {
                  }}
                  onKeyDown={e => newTagInputKeyupHandler(e)}
                  ref={newTagNameElementRef}
                />
                <img src={PlusCircle} alt="" className="position-absolute right-2 top-3 cursor-pointer"
                  onClick={e => newTagProcessHandler(newTagNameElementRef.current)} />
              </div>
            </div>
            <Button borderRadius="6px" w="83px" h="45px"
              onClick={() => saveCustomerTagsButtonClickedHandler()}>Save</Button>
          </>}
        </div>
        <div>
          <h3 className="text-3xl header-h3 font-bold">Folder Template</h3>
          <div className="h-1 bg-yellow-500 w-24 mb-4"></div>
          <div className="mb-4">
            <select id="folderTemplateId"
              className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
              value={state.userProfile?.folderTemplateId ? state.userProfile?.folderTemplateId : ''}
              onChange={folderTemplateSelectBoxChangedHandler}
            >
              <option value="">Please Select One</option>
              {state.folderTemplates.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
            </select>
          </div>
          {!isCreateNewTemplateOpened &&
            <Button borderRadius="6px" w="206px" h="45px" onClick={() => {
              setIsCreateNewTemplateOpened(prevState => !prevState)
            }}>
              CREATE NEW TEMPLATE
            </Button>
          }
          {isCreateNewTemplateOpened &&
            <form onSubmit={createNewTemplateFormSubmittedHandler}>
              <br />
              <div className="mb-4">
                <label htmlFor="templateName" className="block text-sm font-bold text-white uppercase">
                  CREATE NEW TEMPLATE
                </label>
                <input type="text" id="templateName"
                  placeholder="Name your template"
                  className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                  {...folderTemplateForm.register('name', { required: 'Folder template name is required' })}
                  onChange={(e) => {
                    setState(prevState => {
                      return {
                        ...prevState,
                        creatingFolderTemplate: {
                          ...prevState.creatingFolderTemplate,
                          name: e.target.value,
                        }
                      };
                    });
                  }}
                />
                {folderTemplateForm.formState.errors.name &&
                  <span
                    className="text-red-400 text-sm font-bold">{folderTemplateForm.formState.errors.name.message.toString()}</span>}
              </div>
              <div className="mb-4">
                <label htmlFor="folders-0" className="block text-sm font-bold text-white uppercase">
                  CREATE NEW FOLDERS
                </label>

                {state.creatingFolderTemplate.folders.map((f, index, array) => <div
                  className={index === array.length - 1 ? 'relative' : 'relative mb-3'} key={index}>
                  <input type="text" id={`folders-${index}`}
                    placeholder="Folder name"
                    className="w-full h-12 px-3 py-2 bg-black/20 rounded placeholder-gray-200 dark:text-white text-sm border-none focus:ring-white"
                    value={f}
                    onChange={(e) => {
                      const folders = [...state.creatingFolderTemplate.folders];
                      folders[index] = e.target.value;
                      setState(prevState => {
                        return {
                          ...prevState,
                          creatingFolderTemplate: {
                            ...prevState.creatingFolderTemplate,
                            folders: folders,
                          }
                        };
                      });
                    }}
                  />
                  <img src={TimesCircle} alt="" className="absolute right-3 top-4 cursor-pointer"
                    onClick={() => folderNameRemoveButtonClickedHandler(index)} />
                </div>)}
                {folderTemplateForm.formState.errors.folders &&
                  <span
                    className="text-red-400 text-sm font-bold">{folderTemplateForm.formState.errors.folders.message.toString()}</span>}
              </div>
              <div className="mb-4">
                <span className="cursor-pointer" onClick={() => {
                  if (state.creatingFolderTemplate.folders.some(f => f === '')) {
                    return;
                  }
                  setState(prevState => {
                    return {
                      ...prevState,
                      creatingFolderTemplate: {
                        ...prevState.creatingFolderTemplate,
                        folders: [...prevState.creatingFolderTemplate.folders, ''],
                      }
                    };
                  });
                }}>
                  <img src={PlusCircle} alt="" className="inline" /> Add New Folder
                </span>
              </div>
              <Button borderRadius="6px" w="148px" h="45px">
                SAVE TEMPLATE
              </Button>
            </form>
          }
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
};

export default CustomerProfilePage;