import Button from "../../components/common/button/Button";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { getBrandOfficesApi } from "../../services/api-service";
import { getLocalStorageElement } from "../../utils/utils";
import {useNavigate, useParams} from "react-router-dom";

const IntegrationConsent = () => {
  const { officeid } = useParams();
  const navigate = useNavigate();
  const [isAccepted, setIsAccepted] = React.useState(false);
  const iframeOnLoadHandler = (e) => {
    e.style.height = e.contentWindow.document.documentElement.scrollHeight + 'px';
  };

  const confirmButtonClickedHandler = () => {
    if (!isAccepted) {
      toast.error('You must accept the terms and conditions first');
    }
    const storedIdToken = getLocalStorageElement("idToken");
    const officeApi = getBrandOfficesApi(storedIdToken);
    const id = toast.loading('Processing ...');
    officeApi.brandOfficeIntegrationPost({id: +officeid}).then(() => {
      toast.success('Thank you for confirmation!');
      setTimeout(() => navigate("/"), 1000);
    }).catch(() => {
      toast.error('Something went wrong while proceeding your request. Please contact administrator for more information.');
    }).finally(() => {
      toast.dismiss(id);
    });
  };

  return (
    <div className="items-center justify-center p-3">
      <div className="flex items-center justify-center h-full bg-white rounded mb-3">
        <iframe src="/api-terms-trusted-advisers.html" frameBorder={0}
                width={'100%'}
                scrolling={'no'}
                onLoad={(e) => iframeOnLoadHandler(e.target)}
        ></iframe>
      </div>
      <div className={"flex items-center"}>
        <div>
          <input type="checkbox" id="api-terms-accept" checked={isAccepted}
                 onChange={(e) => setIsAccepted(e.target.checked)}/>
          &nbsp;
          &nbsp;
          <label htmlFor="api-terms-accept">Accept the terms and conditions</label>
        </div>
        <div className={"ms-auto"}>
          <Button h="40px" px="18px" borderRadius="0.375rem" onClick={() => confirmButtonClickedHandler()}
                  isDisabled={!isAccepted}>
            <span>Confirm</span>
          </Button>
        </div>
      </div>
      <Toaster position="top-center" reverseOrder={true} />
    </div>
  );
};
export default IntegrationConsent;